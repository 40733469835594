import { COMPANY_USER_LIST } from './mutation-types'
import { COMPANY_LIST } from './mutation-types'
import { USER_INFO } from './mutation-types'
import { TENANT_ID } from './mutation-types'

export default {
  [COMPANY_USER_LIST](state, { data }) {
    state.companyUserList = data
  },
  [COMPANY_LIST](state, { data }) {
    state.companyList = data
  },
  [USER_INFO](state, { data }) {
    state.userInfo = data
  },
  [TENANT_ID](state, { data }) {
    state.tenantId = data
  },
}
