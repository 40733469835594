<template>
  <div class="container">
    <Sidebar></Sidebar>
    <div class="main">
      <div class="content">
        <router-view></router-view>
      </div>
      <div class="quickJump"></div>
    </div>
  </div>
</template>

<script>
import Sidebar from "./sidebar";
export default {
  components: {
    Sidebar,
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.container {
  display: flex;
  .main {
    flex: 1;
    display: flex;
    height: 100vh;
    overflow-y: auto;
    .content {
      width: 800px;
    }
  }
}

</style>
