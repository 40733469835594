//全局自定义指令
import Vue from 'vue';

const preventReClick = Vue.directive('preventReClick',{
    inserted:function(el,binding,){
    	//console.log(binding.value,vNode,oldNode); // 3000
        el.addEventListener('click',()=>{
            if(!el.disabled){
                el.disabled = true;
                setTimeout(()=>{
                    el.disabled = false;
                },binding.value || 1000); //可以传值，或默认为1000
            }
        })
    }
})

export {
    preventReClick
}
