<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {
    if (
      sessionStorage.getItem("companyUserList") != "undefined" &&
      sessionStorage.getItem("companyUserList") != null
    ) {
      this.$store.dispatch(
        "companyUserList",
        JSON.parse(sessionStorage.getItem("companyUserList"))
      );
      this.$store.dispatch(
        "companyList",
        JSON.parse(sessionStorage.getItem("companyList"))
      );
    }
    if (
      sessionStorage.getItem("userInfo") != "undefined" &&
      sessionStorage.getItem("userInfo") != null
    ) {
      this.$store.dispatch(
        "userInfo",
        JSON.parse(sessionStorage.getItem("userInfo"))
      );
    }
    if (
      sessionStorage.getItem("tenantId") != "undefined" &&
      sessionStorage.getItem("tenantId") != null
    ) {
      this.$store.dispatch(
        "tenantId",
        JSON.parse(sessionStorage.getItem("tenantId"))
      );
    }
    const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if(userInfo){
      if(userInfo.upCompanyId === 0 ){
          userInfo.upCompanyId = '';
      }
      if( userInfo.upDeptmentId === 0){
          userInfo.upDeptmentId = '';
      }
      sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
    }
  },
};
</script>

<style lang="less">
body {
  background-color: #f5f4f7;
  padding: 0;
  margin: 0;
  font-size: 13px;
}
.box {
  .headline {
    font-size: 28px;
    color: #1f2d3d;
    padding: 28px 40px 4px;
    line-height: 45px;
  }
  .content {
    padding: 15px 0 0 40px;
    p {
      color: #141e31;
      font-size: 16px;
      line-height: 26px;
      margin: 8px 0;
    }
  }
}
p {
  padding: 0;
  margin: 0;
}
.el-container {
  padding: 0 !important;
}
/* table */
.el-table {
  font-size: 12px !important;
}
.el-table thead {
  color: rgb(96, 95, 95);
}
.el-table .el-table__cell {
  text-align: center !important;
}
.el-table__cell {
  padding: 8px 0 !important;
  background-color: #f8f9fd;
}
.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background-color: white !important;
}
.el-table th.el-table__cell.is-leaf,
.gutter {
  border-bottom: 3px solid #e8e9ebdb !important;
}
.el-table__row td {
  border-bottom: none !important;
}

// 布局
.el-main {
  position: relative;
  overflow: hidden !important;
  padding: 15px !important;
}
/* 输入框 */
.el-input__inner {
  height: 30px !important;
  font-size: 12px !important;
  white-space: nowrap !important;
  line-height: 0 !important;
}

// 切换头部
._Tab {
  border-bottom: 2px solid #c0c0c03a;
  padding-bottom: 7px;
  > span {
    margin-right: 40px;
    padding-bottom: 5px;
  }
  .activeColor {
    border-bottom: 3px solid #2f98ff;
    color: #2f98ff;
  }
}
._Inp {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 10px;
  span {
    width: 100px;
    text-align: right;
  }
}

// 下拉框(样式完整)
._Select {
  width: 230px;
  display: flex;
  align-items: center;
  > span {
    width: 100px;
    text-align: right;
  }
}
.el-input__icon {
  line-height: 30px !important;
}
.el-dialog__body {
  font-size: 13px !important;
  border-top: 1px solid #c0c0c055;
  margin: 0 20px;
  padding: 30px 0 !important;
}
// 按钮
.el-button {
  // 蓝色背景按钮
  font-size: 12px !important;
  border-radius: 2px !important;
  padding: 0px 10px !important;
  height: 32px !important;
  line-height: 32px !important;
  i {
    line-height: 32px !important;
  }
}
.el-button--primary{
  background-color: #1361FF !important;
  border-color: #1361FF !important;
}
.el-button--primary.is-plain {
    color: #409EFF;
    background: #ecf5ff !important;
    border-color: #b3d8ff !important;
}
.el-button--primary.is-plain:hover{
  background: #409EFF !important;
}
._ImgBtn {
  //el-button无背景颜色带边框，并可添加图片
  span {
    display: flex;
    align-items: center;
  }
  img {
    // margin-right: 5px;
    width: 15px;
    height: 15px;
  }
}
// 分页
._TableBox {
  border: 1px solid rgba(192, 192, 192, 0.363);
  background-color: white;
  ._Pagination {
    text-align: end;
    padding: 10px 20px;
  }
}

// 公用子页面
._BoxHeader {
  background-color: #4274c0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 0 20px;
  height: 40px;
}
.PageContain {
  position: absolute;
  width: calc(100% - 30px);
  background-color: white;
  top: 15px;
  bottom: 15px;
}
._SelectHeader {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  & .el-button {
    margin-left: 10px;
  }
  > ._Inp {
    margin-top: 0 !important;
  }
}
._SelectHeaderColor {
  background-color: #f0f0f0;
  padding-left: 10px;
}
._Title {
  background-color: #edf2fa;
  padding: 8px 12px;
  display: inline-block;
}
._Table {
  padding: 0 10px;
  width: 98%;
}
._TypeBox {
  width: 105px;
  height: 40px;
  color: #000000;
  background: #ffffff;
  border: 1px solid #e7e9ec;
  box-shadow: 0px 2px 8px 0px rgba(160, 164, 170, 0.36);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
}
.el-image-viewer__img {
  width: 50% !important;
  height: auto;
}
.image-slot {
  z-index: 3000 !important;
}
span {
  cursor: pointer;
}
.el-tree-node:focus > .el-tree-node__content {
  background-color: #d4e5f9 !important;
  height: 40px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  margin-top: 0;
  color: #333333;
}
.el-tree-node__content {
  height: 32px !important;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.el-tree-node__content:hover {
  background-color: #d4e5f9;
}
.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: #d4e5f9;
}
</style>
