<template>
  <el-menu
    :default-active="activeIndex"
    @select="handleSelect"
    @open="handleOpen"
    @close="handleClose"
    unique-opened
    router
  >
    <el-submenu index="/Summarize">
      <template slot="title">
        <i class="el-icon-document"></i>
        <span>概述</span>
      </template>
      <el-menu-item-group>
        <el-menu-item index="/Setting">软件背景</el-menu-item>
        <el-menu-item index="/Expository">手册编写说明</el-menu-item>
      </el-menu-item-group>
    </el-submenu>
    <el-menu-item index="/FunctionalProfile">
      <template slot="title">
        <i class="el-icon-menu"></i>
        <span slot="title">功能概况</span>
      </template>
    </el-menu-item>
    <el-submenu index="/InitialValue">
      <template slot="title">
        <i class="el-icon-tickets"></i>
        <span>基础资料</span>
      </template>
      <el-menu-item-group>
        <el-menu-item index="/CompanySetup">公司设置</el-menu-item>
        <el-menu-item index="/SectorSetup">部门设置</el-menu-item>
        <el-menu-item index="/PostSetting">岗位设置</el-menu-item>
        <el-menu-item index="/Functional">职能维护</el-menu-item>
        <el-menu-item index="/Personnel">人员管理</el-menu-item>
        <el-menu-item index="/Permission">角色权限设置</el-menu-item>
        <el-menu-item index="/PersonalCenter">个人中心</el-menu-item>
        <el-menu-item index="/District">区域管理</el-menu-item>
        <el-menu-item index="/DesignCategory">涉及范畴</el-menu-item>
        <el-menu-item index="/Safeguard">岗位与职能维护</el-menu-item>
      </el-menu-item-group>
    </el-submenu>
    <el-submenu index="/BankSettingIndex">
      <template slot="title">
        <i class="el-icon-folder"></i>
        <span>题库设置</span>
      </template>
      <el-menu-item-group>
        <el-menu-item index="/BankSettingIndex">岗位职能表</el-menu-item>
      </el-menu-item-group>
    </el-submenu>
    <el-submenu index="/checkProessIndex">
      <template slot="title">
        <i class="el-icon-connection"></i>
        <span>检查流程</span>
      </template>
      <el-submenu index="/checkProessIndex">
        <template slot="title">现场检查</template>
        <el-menu-item index="/checkProessIndex">新做一次现场检查</el-menu-item>
      </el-submenu>
      <el-menu-item-group>
        <el-menu-item index="/checkProessProcess2">体系落地检查</el-menu-item>
        <el-menu-item index="/checkProessProcess3">考核细则</el-menu-item>
      </el-menu-item-group>
    </el-submenu>
    <el-submenu index="bankManage1">
      <template slot="title">
        <i class="el-icon-folder-opened"></i>
        <span>题库管理</span>
      </template>
      <el-menu-item-group>
        <el-menu-item index="bankManage1">题库维护</el-menu-item>
        <el-menu-item index="bankManage2">临时题库</el-menu-item>
        <el-menu-item index="bankManage3">内外审问题点题库</el-menu-item>
        <el-menu-item index="bankManage4">题库导入</el-menu-item>
        <el-menu-item index="bankManage5">考试题库</el-menu-item>
        <el-menu-item index="bankManage6">专题题库</el-menu-item>
      </el-menu-item-group>
    </el-submenu>
   <el-menu-item index="/draw-statistics">
      <template slot="title">
        <i class="el-icon-menu"></i>
        <span slot="title">抽题统计</span>
      </template>
    </el-menu-item>
    <el-submenu index="/special-subject-study">
      <template slot="title">
        <i class="el-icon-reading"></i>
        <span>学习模块</span>
      </template>
      <el-menu-item-group>
        <el-menu-item index="/special-subject-study">专题学习</el-menu-item>
        <el-menu-item index="/file-study">文件学习</el-menu-item>
      </el-menu-item-group>
    </el-submenu>
  </el-menu>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: "",
    };
  },
  created() {},
  methods: {
    handleSelect(key) {
      this.activeIndex = key;
    },
    handleOpen(key) {
      this.$router.push({ path: key });
      this.activeIndex = key;
    },
    handleClose(key) {
      this.$router.push({ path: key });
      this.activeIndex = key;
    },
  },
};
</script>

<style lang="less" scoped>
/* 整体滚动条 */
::-webkit-scrollbar {
  display: none;
}
.el-menu {
  width: 200px;
  height: 100vh;
  overflow-y: auto;
}
</style>

