import { COMPANY_USER_LIST } from './mutation-types'
import { COMPANY_LIST } from './mutation-types'
import { USER_INFO } from './mutation-types'
import { TENANT_ID } from './mutation-types'

export default {
  companyUserList({ commit }, data) {
    commit(COMPANY_USER_LIST, { data })
  },
  companyList({ commit }, data) {
    commit(COMPANY_LIST, { data })
  },
  userInfo({ commit }, data) {
    commit(USER_INFO, { data })
  },
  tenantId({ commit }, data) {
    commit(TENANT_ID, { data })
  }
} 